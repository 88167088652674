<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('nav.dashboard')"
    />

    <div class="mb-5 financial_card_container grid-3">


      <financial-card
          :number="getData.total_products"
          :title="$t('financial.card.total_products')"
          icon="f-products-icon"
          :is_price="false"
      />
      <financial-card
          :number="getData.total_orders"
          :title="$t('financial.card.total_orders')"
          icon="f-orders-icon"
          :is_price="false"
      />
      <financial-card
          :number="getData.total_orders_sales"
          :title="$t('financial.card.total_sales')"
          icon="f-sales-icon"
      />
      <financial-card
          :number="getData.vendors_profit"
          :title="$t('financial.card.total_profit')"
          icon="f-profit-icon"
      />

    </div>

    <div class="mb-5 home_echart_flex ">
      <echart-bar class="w-4" :data="getData.orders_data"/>
      <echart-pie class="w-2" :males="getData.users.males" :females="getData.users.females"/>
    </div>

    <div class="mb-5 home_echart_flex ">

      <div class="chart_card w-2-r">
        <div class="chart__heading mb-5">
          <div class="chart__heading-title">{{ $t('chart.tile.title') }}</div>
          <div class="chart__heading-subtitle">{{ $t('chart.last_12_month') }}</div>
        </div>

        <template v-if="getData.popular_products.length>0">
          <chart-tile
              v-for="(item, index) in getData.popular_products"
              :key="index"
              :color="productsColors[index]"
              :style="index===4?'margin-bottom: 0;':''"
              :total="getData.total_popular_products"
              :data="item"/>
        </template>
        <wameed-no-data v-else/>
      </div>


      <echart-line class="w-4-r" :data="getData.sales_data"/>
    </div>


    <div class="d-flex flex-wrap flex-1" v-if="getData.pending_orders">

      <b-col lg="12" md="12" class="px-0">
        <div class="w-table d-flex flex-column w-table-min_unset">
          <div style="padding:16px 26px 0" class=" d-flex justify-content-between align-content-center flex-wrap">
            <span class="text-med-22">{{ $t('home.orders.title') }}</span>
            <router-link :to="{name:'orders'}" class="text-reg-20">{{ $t('home.orders.btn') }}</router-link>
          </div>
          <w-tables
              :noDataTitle="$t('table.no_data_title')"
              :noDataSubtitle="$t('table.no_data_subtitle')"
              v-if="getData.pending_orders"
              :items="getData.pending_orders"
              :fields="fields"
              :custimized-items="[
                { name: 'date' },
                { name: 'action' },
                { name: 'price' },
                { name: 'status' },
                { name: 'rate' },
              ]"
          >
            <template slot="date" slot-scope="{ data }">
              <div class="d-flex">
                <span class="text-font-main mx-3">{{ formatDate(data.item.date) }}</span>
              </div>
            </template>


            <template slot="price" slot-scope="{ data }">
              <check-price
                  :price="Number(data.item.price)+Number(data.item.service_fee)"
                  :dis-price="Number(data.item.price)+Number(data.item.service_fee)-Number(data.item.dis_price)"
              />
            </template>

            <template slot="rate" slot-scope="{ data }">
              <customer-rate :rate="data.item.rate"/>
            </template>

            <template slot="status" slot-scope="{ data }">
              <custom-badge :text="data.item.status_name" :color="data.item.status_color"/>
            </template>


            <template slot="action" slot-scope="{ data }">
              <b-dropdown
                  variant="background"
                  class="table-option"
                  toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                  menu-class="rounded-8"
                  no-caret
                  dropleft
              >
                <template v-slot:button-content>
                  <vertical-dots-icon/>
                </template>

                <b-dropdown-item
                    :to="toToDetail(data.item.id)"
                >
                    <span class="text-regular-14 text-font-secondary permission-show-detail">
                      <eyeon-icon class="mx-2"/>
                      {{ $t('common.show_details') }}
                    </span>
                </b-dropdown-item>


              </b-dropdown>
            </template>


          </w-tables>
        </div>
      </b-col>

    </div>

  </div>
</template>

<script>
import {WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'

import {mapGetters} from 'vuex';

import FinancialCard from "@/components/financialCard";

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import EchartPie from "@/views/pages/dashboard/charts/echart-pie";
import EchartLine from "@/views/pages/dashboard/charts/echart-line";
import EchartBar from "@/views/pages/dashboard/charts/echart-bar";
import ChartTile from "@/views/pages/dashboard/components/chartTile";
import {WameedNoData} from "wameed-ui/dist/wameed-ui.esm"
import CheckPrice from "@/views/pages/products/components/checkPrice.vue";
import CustomerRate from "@/components/customerRate.vue";
import CustomBadge from "@/components/customBadge.vue";
import CheckValue from "@/views/pages/products/components/checkValue.vue";

export default {
  components: {
    CheckValue,
    CustomBadge,
    CustomerRate,
    CheckPrice,
    EchartBar,
    WTables,
    EchartPie,
    EchartLine,
    WameedNoData,
    ChartTile,
    FinancialCard,
    PageHeader
  },
  data() {
    return {
      "productsColors": [
        "#00C0F3",
        "#28C76F",
        "#FF9F43",
        "#7367F0",
        "#f29030"
      ],

      fields: [
        {
          key: "index",
          label: "#",
          sortable: true,
        },

        {
          key: 'no',
          label: this.$i18n.t('orders.table.no'),
          sortable: true,
        },

        {
          key: 'date',
          label: this.$i18n.t('orders.table.date'),
          sortable: true,
        },

        {
          key: 'price',
          label: this.$i18n.t('table.price'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$i18n.t('orders.table.status'),
          sortable: true,
        },
        {
          key: 'rate',
          label: this.$i18n.t('orders.table.rate'),
          sortable: true,
        },
        {
          key: 'action',
          label: this.$i18n.t('table.options')
        }
      ],
    };
  },
  computed: {
    ...mapGetters({
      getData: 'vendor/home/getData',
    }),

  },
  methods: {
    toToDetail(id) {
      return {
        name: 'order-detail',
        params: {lang: this.$i18n.locale, id},
      };
    }
  },
  created() {
    this.$store.dispatch('vendor/home/get');
  },

};
</script>
