<template>
  <div class="financial_card">
    <div>
      <div class="financial_card--title">{{ title }}</div>
      <div class="financial_card--count">{{ formatNumber(number, is_price) }}</div>
    </div>
    <component
        v-if="icon"
        v-bind:is="icon"
    ></component>
  </div>
</template>

<script>
export default {
  name: 'FinancialCard',
  props: {
    title: String,
    number: [String, Number],
    is_price: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: null,
    },

  },
};
</script>
