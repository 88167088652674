<template>
  <div class="chart-container">
    <div class="chart__heading">
      <div class="chart__heading-title">{{ $t('chart.bar.title') }}</div>
      <div class="chart__heading-subtitle">{{ $t('chart.last_12_month') }}</div>
    </div>
    <v-chart class="chart" :option="option" :autoresize="true"/>
  </div>
</template>

<script>

export default {

  props: {
    data: Object
  },
  computed: {
    months() {
      let temp = [];

      for (let i = 0; i < this.data.months.length; i++) {
        let _temp = this.data.months[i];
        temp.push(this.$i18n.t('months.' + _temp.toString().toLowerCase()))
      }
      return temp;
    },

    option() {
      return {
        tooltip: {

          formatter: "<span class='tool_tip_text'><span>" + this.$i18n.t('common.order') + "</span>" + "{c} </span> ",
          className: "char-bar-tooltip",
          position: 'top'
        },

        legend: {
          show: false,
        },
        textStyle: {
          fontFamily: 'din-next'
        },
        color: ['#EDF0F5'],
        grid: {
          left: '0%',
          right: '0%',
          bottom: '14%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: this.months
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Direct',
            type: 'bar',
            barWidth: '60%',
            data: this.data.orders,
            barMaxWidth: 30,
            itemStyle: {
              borderRadius: 5,
            },
            emphasis: {
              scaleSize: 4,
              itemStyle: {
                color: '#245389',
              }
            },
          },

        ],
        media: [ // each rule of media query is defined here
          {
            query: {
              maxAspectRatio: 1
            },   // write rule here

          },
        ]
      };
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped>

</style>
