<template>
  <div class="chart-container">
    <div class="chart__heading">
      <div class="chart__heading-title">{{ $t('chart.pie.title') }}</div>
      <div class="chart__heading-subtitle">{{ $t('chart.last_12_month') }}</div>
    </div>
    <v-chart class="chart" :option="option" :autoresize="true"/>
  </div>
</template>

<script>

export default {

  props: {
    males: [Number, String],
    females: [Number, String],
  },

  computed: {
    option() {
      return {
        tooltip: {
          show: true,
          formatter: "{b} : {c} ({d}%)"
        },
        legend: {
          orient: 'horizontal',
          bottom: '20%',
          selectedMode: false,
          icon: 'circle',
          align: 'right'
        },
        textStyle: {
          fontFamily: 'din-next'
        },
        color: [
          '#1CD0B7',
          '#FA4798',
        ],
        series: [
          {
            type: 'pie',
            radius: ['45%', '60%'],
            avoidLabelOverlap: true,
            clockwise: false,
            startAngle: 90,
            bottom: '20%',
            emphasis: {
              scaleSize: 4,
              itemStyle: {
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0,0)'
              }
            },
            labelLine: {
              show: true
            },
            label: {

              formatter: " {c}  \n {b} ",

            },
            data: [
              {value: this.males, name: this.$t('chart.males')},
              {value: this.females, name: this.$t('chart.females')},
            ]
          }
        ],
        media: [ // each rule of media query is defined here
          {
            query: {
              maxAspectRatio: 1
            },   // write rule here

          },
        ]
      };
    }
  }
};
</script>

<style scoped>

</style>
