<template>
  <div class="status-container custom" :style="`--color:#${color};--bgColor:${bgColor}`">
    <span class="status-container--text text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "customBadge",
  props: {
    text: null,
    color: null
  },
  computed: {
    bgColor() {
      let rgb = this.hexToRgb('#' + this.color);
      return `rgba(${rgb.r},${rgb.g},${rgb.b},.1)`
    }
  },

  methods: {
    hexToRgb(hex) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }
  },
}
</script>

